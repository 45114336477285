import { graphql, Link } from "gatsby"
import React from "react"
import Carousel from "../components/Carousel"
import Layout from "../components/Layout"
import * as styles from "../styles/home.module.css"
import { GatsbySeo } from "gatsby-plugin-next-seo"

export default function Home({ data }) {
  return (
    <Layout>
      <GatsbySeo
        title="Tecklenburg, Jenkins & Jacoby, LLC | Maritime Attorney | Charleston SC"
        description="A law firm focused on solving problems, not aggravating them. We believe
	that an attorney should be an asset and not just an expense, and that our clients goals must be taken as our 		
        own. Areas of practice include, admiralty, litigation, business law, transportation law, real estate law, 
        construction law, and environmental law."
        keywords="Charleston, South Carolina, Maritime Attorney, Maritime Lawyer, Maritime Lawyers, Lawyer, Maritime, Attorney, Admiralty Attorney, Admiralty Lawyer, Admiralty, Business Attorney, Business Lawyer, Business, Railroad Attorney, Railroad Lawyer, Railroad, Litigation Attorney, Litigation Lawyer, Litigation, Construction Attorney, Construction Lawyer, Construction, Estate Attorney, Estate Lawyer, Estate"
        canonical="https://www.tecklaw.net/"
        openGraph={{
          type: "website",
          url: "https://tecklaw.net",
          images: [
            {
              url: "../images/Logo.jpg",
              width: 691,
              height: 108,
              alt: "Tecklenburg, Jenkins & Jacoby, LLC Logo",
            },
          ],
        }}
      />
      <div style={{ width: "100%" }}>
        <Carousel data={data} />
      </div>
      <section className={styles.header}>
        <div>
          <h1>South Carolina’s Top Maritime Lawyers</h1>
          <p>
            The partners and associates of Tecklenburg, Jenkins & Jacoby, LLC
            are South Carolina’s top maritime attorneys, providing superior
            legal services in the areas of maritime, transportation, and
            transactional law.
          </p>
          <Link className={styles.btn} to="/about">
            About Us
          </Link>
        </div>
        <div className={styles.contact}>
          <h2>Our Contact Info</h2>
          <p>
            Tecklenburg, Jenkins & Jacoby, LLC <br />
            1250 Folly Road <br />
            Charleston, SC 29412 <br />
            Phone: (843) 534-2628 <br />
            Facsimile: (843) 534-2629
          </p>
          <Link className={styles.btn} to="/contact">
            Contact Us
          </Link>
        </div>
        <section>
          <div className={styles.admiralty}>
            <h2>South Carolina Legal Services</h2>
            <br />
            <h3>South Carolina Admiralty & Maritime Law</h3>
            <p>
              Admiralty law is a detailed set of laws which govern maritime
              disputes and offenses.…
            </p>
            <Link
              className={styles.btn}
              to="/practice-areas/south-carolina-admiralty-maritime-law"
            >
              Maritime Law
            </Link>
          </div>
          <div className={styles.businessLaw}>
            <h3>Business Law</h3>
            <p>
              Business success begins with the right business advice and
              representation...
            </p>
            <Link
              className={styles.btn}
              to="/practice-areas/south-carolina-business-law"
            >
              Form an LLC
            </Link>
          </div>
          <div className={styles.litigation}>
            <h3>Litigation</h3>
            <p>
              The civil litigation process, like most legal matters, need not be
              lengthy and tedious…
            </p>
            <Link
              className={styles.btn}
              to="/practice-areas/south-carolina-litigation"
            >
              Let's Help Today
            </Link>
          </div>
        </section>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "carousel" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(height: 350, width: 1300)
          }
          base
        }
      }
    }
  }
`
