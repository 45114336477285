import React, { useState, useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import * as styles from "../styles/carousel.module.css"

export default function Carousel(props) {
  const paragraphText = [
    "Maritime law consists of both domestic laws governing maritime activities and private international laws. These maritime laws or “marine laws” regulate the legal relationships between private entities which operate vessels on the ocean.",
    "Business success begins with the right business advice and representation. Our business law attorneys give you the advantage of having decades of transactionel and litigation experience on your side. We represent our clients through every stage of business law, from choosing your legal entity to acquisition and mergers.",
    "If you own a company in the transportation industry, you need a lawyer who will help you with the significant legal and regulatory issues that mey keep your business from moving forward. Tecklenburg, Jenkins & Jacoby, LLC represent businesses, insurers and shippers with all of the challenges they may face, providing sound legal guidance resulting in positive outcomes.",
  ]
  const headerText = [
    "South Carolina Maritime Lawyers",
    "South Carolina Business Transaction Lawyers",
    "South Carolina Transportation Lawyers",
  ]
  const urls = [
    "/practice-areas/south-carolina-admiralty-maritime-law",
    "/practice-areas/south-carolina-business-law",
    "/practice-areas/south-carolina-transportation-law",
  ]

  const alts = [
    "South Carolina Maritime Law",
    "South Carolina Business Law",
    "South Carolina Transportation Law",
  ]

  const images = props.data.allFile.edges

  const [index, set] = useState(0)
  const { node } = props.data.allFile.edges[index]

  useEffect(
    () =>
      void setInterval(() => set(index => (index + 1) % images.length), 5000),
    [images.length]
  )

  return (
    <div>
      <Link to={urls[index]} className={styles.image}>
        <GatsbyImage
          image={node.childImageSharp.gatsbyImageData}
          key={node.id}
          alt={alts[index]}
        />
        <span>
          <h2 style={{ color: "white" }}>{headerText[index]}</h2>
          <p>{paragraphText[index]}</p>
        </span>
      </Link>
    </div>
  )
}
